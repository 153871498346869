import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";

const AccessibilitàPage: React.FC = () => {
  return (
    <Layout>
      <Head title="Accessibilità" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack spacing={4} direction="column">
            <Heading
              as={"h1"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              textAlign={"left"}
              fontFamily={"body"}
              color={"accent.01"}
            >
              Accessibilità
            </Heading>
            <Heading
              as={"h5"}
              size="md"
              fontFamily={"body"}
              color={"accent.01"}
            >
              Il nostro impegno per una rete più inclusiva e accessibile
            </Heading>

            <Text textAlign={"justify"}>
              L’Agenzia per l’Italia Digitale (AgID) definisce l’accessibilità
              come la capacità dei sistemi informatici di erogare servizi e
              fornire informazioni fruibili, senza discriminazioni, anche da
              parte di coloro che a causa di disabilità necessitano di
              tecnologie assistive o configurazioni particolari. Hyundai Capital
              Bank Europe GmbH Italy si impegna a garantire l'accessibilità dei
              suoi touchpoint a tutti gli utenti, indipendentemente da eventuali
              limitazioni fisiche o cognitive, adeguandosi alle disposizioni
              della Legge Stanca, alle normative vigenti in materia di
              accessibilità digitale e alle linee guida AgID. Siamo consapevoli
              che l'accessibilità digitale è un elemento fondamentale per
              garantire un'esperienza di navigazione inclusiva per tutti gli
              utenti. Pertanto, ci impegniamo costantemente a migliorare
              l’accessibilità dei nostri touchpoint ed eliminare qualsiasi
              “barriera digitale” che possa impedire una fruizione equa ed
              efficace dei nostri servizi.
            </Text>
            <Heading
              as={"h5"}
              size="md"
              fontFamily={"body"}
              color={"accent.01"}
            >
              Dichiarazioni di accessibilità
            </Heading>

            <Text textAlign={"justify"}>
              Puoi scaricare le dichiarazioni di accessibilità relative ai siti
              dell'ecosistema Hyundai Capital dalla lista sottostante
            </Text>
            <UnorderedList pl={5}>
              <ListItem>
                <Link
                  color="accent.01"
                  href="/documents/accessibilita/Dichiarazione di accessibilita sito web per i Soggetti Privati di cui all.pdf"
                  isExternal
                >
                  Documento di Accessibilità
                </Link>
              </ListItem>
            </UnorderedList>

            <Heading
              as={"h5"}
              size="md"
              fontFamily={"body"}
              color={"accent.01"}
            >
              Segnalazioni
            </Heading>

            <Text textAlign={"justify"}>
              In caso di difficoltà nell’accesso alle informazioni presenti sui
              nostri touchpoint o di suggerimenti per migliorarne
              l’accessibilità, vi preghiamo gentilmente di contattarci
              all’indirizzo e-mail{" "}
              <Link
                color="accent.01"
                href="mailto:accessibilita@it.hcs.com"
                isExternal
              >
                accessibilita@it.hcs.com
              </Link>
              . Saremo lieti di ascoltarvi e faremo del nostro meglio per
              rispondere alle vostre esigenze.{" "}
              <Heading
                as={"h6"}
                fontSize={["15px", "15px"]}
                fontWeight={"700"}
                fontFamily={"body"}
                color={"accent.01"}
              >
                <Link
                  color="accent.01"
                  href="mailto:accessibilita@it.hcs.com"
                  isExternal
                >
                  &#8250; Inviaci la tua segnalazione
                </Link>
              </Heading>
            </Text>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default AccessibilitàPage;
